<template>
  <div class="msg_container">
    <h5 class="mt-heading mt-heading--s mt-heading--center mt-heading--underline">
      {{ $t('ui.message.ohNo') }}
    </h5>
    <span style="font-size: 100px">&#128556;</span>
    <p class="error">
      {{ $t('ui.message.error') }}
    </p>
    <p class="error">
      {{ $t('ui.message.sorry') }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'Error',
  data() {
    return {
      loading: false,
      text: '&#1F440'
    };
  },
  computed: {},
  methods: {}
};
</script>
<style scoped>
.mt-heading--underline::after {
  content: '';
  display: block;
  height: 0.25rem;
  background-color: #df382b;
}
</style>
